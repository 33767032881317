interface Title {
  small: string
  big: string
}

interface Category {
  id: number
  name: string
  link: string
}

interface SpmItem {
  id: number
  name: string
}

interface Intro {
  content: string
  spmList: SpmItem[]
}

interface Description {
  content: string
}

interface Project {
  id: number
  title: Title
  clientURLLink: string
  projectHeaderImage: string
  videoLink: string
  date: string
  categories: Category[]
  intro: Intro
  description: Description
}

import Cobertura from '../../src/assets/images/servicesDetails/cobertura.webp'
import Escadas from '../../src/assets/images/servicesDetails/escadas.webp'
import Janela01 from '../../src/assets/images/servicesDetails/janela01.webp'
import Janelas02 from '../../src/assets/images/servicesDetails/janela02Details.webp'
import Piscina01 from '../../src/assets/images/servicesDetails/piscina01.webp'
import Futebol from '../../src/assets/images/servicesDetails/quadracondominio.webp'
import Sacada01 from '../../src/assets/images/servicesDetails/sacada01.webp'
import Sacada02 from '../../src/assets/images/servicesDetails/sacada02.webp'
import sacada04 from '../../src/assets/images/servicesDetails/sacada04.webp'
import Sacada05 from '../../src/assets/images/servicesDetails/sacada05.webp'

const projectData: Project[] = [
  {
    id: 1,
    title: {
      small: 'Proteção de ',
      big: 'Janela'
    },
    clientURLLink: '#',
    projectHeaderImage: `${Janela01}`,
    videoLink: '',
    date: '6 August 2022',
    categories: [
      {
        id: 1,
        name: 'Janelas',
        link: '#'
      }
    ],
    intro: {
      content: 'Instalação de tela de proteção em janela residencial.',
      spmList: [
        {
          id: 1,
          name: 'Proteção de janela'
        },
        {
          id: 1,
          name: 'Instalação de tela de proteção'
        },
        {
          id: 1,
          name: 'Segurança para residências'
        }
      ]
    },
    description: {
      content:
        'A instalação de tela de proteção em janela residencial é essencial para garantir a segurança da sua casa. Além de oferecer proteção contra quedas acidentais, as telas também evitam a entrada de insetos indesejados, como mosquitos e moscas. Com a proteção de janela adequada, você pode desfrutar de ar fresco e luz natural sem se preocupar com a segurança da sua família.'
    }
  },
  {
    id: 2,
    title: {
      small: 'Proteção de ',
      big: 'Escadas'
    },
    clientURLLink: '#',
    projectHeaderImage: `${Escadas}`,
    videoLink: '',
    date: '6 August 2022',
    categories: [
      {
        id: 1,
        name: 'Escadas',
        link: '#'
      }
    ],
    intro: {
      content:
        'Proteção de escadas com tela de segurança para garantir a segurança de crianças e animais de estimação.',
      spmList: [
        {
          id: 1,
          name: 'Proteção de escadas'
        },
        {
          id: 1,
          name: 'Instalação de tela de proteção'
        },
        {
          id: 1,
          name: 'Segurança para residências'
        }
      ]
    },
    description: {
      content:
        'A instalação de tela de proteção em janela residencial é essencial para garantir a segurança da sua casa. Além de oferecer proteção contra quedas acidentais, as telas também evitam a entrada de insetos indesejados, como mosquitos e moscas. Com a proteção de janela adequada, você pode desfrutar de ar fresco e luz natural sem se preocupar com a segurança da sua família.'
    }
  },
  {
    id: 3,
    title: {
      small: 'Proteção de ',
      big: 'Externa'
    },
    clientURLLink: '#',
    projectHeaderImage: `${Sacada01}`,
    videoLink: '',
    date: '6 August 2022',
    categories: [
      {
        id: 1,
        name: 'Sacadas',
        link: '#'
      }
    ],
    intro: {
      content:
        'Telas de proteção instaladas em áreas externas para evitar a entrada de animais indesejados.',
      spmList: [
        {
          id: 1,
          name: 'Proteção externa'
        },
        {
          id: 1,
          name: 'Instalação de tela de proteção'
        },
        {
          id: 1,
          name: 'Segurança para residências'
        }
      ]
    },
    description: {
      content:
        'As telas de proteção instaladas em áreas externas são uma solução eficaz para evitar a entrada de animais indesejados, como pássaros e gatos, em sua propriedade. Além disso, elas também podem servir como uma barreira adicional de segurança, impedindo o acesso não autorizado à sua casa ou estabelecimento. Com a proteção externa adequada, você pode desfrutar de espaços ao ar livre sem preocupações.'
    }
  },
  {
    id: 4,
    title: {
      small: 'Proteção de ',
      big: 'Piscinas'
    },
    clientURLLink: '#',
    projectHeaderImage: `${Piscina01}`,
    videoLink: '',
    date: '6 August 2022',
    categories: [
      {
        id: 1,
        name: 'Piscinas',
        link: '#'
      }
    ],
    intro: {
      content:
        'Instalação de tela de proteção em piscinas de apartamentos e casas.',
      spmList: [
        {
          id: 1,
          name: 'Proteção de piscinas'
        },
        {
          id: 1,
          name: 'Instalação de tela de proteção'
        },
        {
          id: 1,
          name: 'Segurança para residências'
        }
      ]
    },
    description: {
      content:
        'A instalação de tela de proteção em janela residencial é essencial para garantir a segurança da sua casa. Além de oferecer proteção contra quedas acidentais, as telas também evitam a entrada de insetos indesejados, como mosquitos e moscas. Com a proteção de janela adequada, você pode desfrutar de ar fresco e luz natural sem se preocupar com a segurança da sua família.'
    }
  },
  {
    id: 5,
    title: {
      small: 'Proteção de ',
      big: 'Varandas'
    },
    clientURLLink: '#',
    projectHeaderImage: `${Sacada05}`,
    videoLink: '',

    date: '6 August 2022',
    categories: [
      {
        id: 5,
        name: 'Varandas',
        link: '#'
      }
    ],
    intro: {
      content:
        'Instalação de telas de proteção em varandas para garantir a segurança de crianças e animais de estimação.',
      spmList: [
        {
          id: 5,
          name: 'Proteção de varandas'
        }
      ]
    },
    description: {
      content:
        'As telas de proteção instaladas em áreas de lazer são projetadas para garantir a segurança das crianças durante a diversão. Elas oferecem uma barreira de proteção em torno de playgrounds, áreas de recreação e quintais, evitando quedas e possíveis acidentes. Com as telas de proteção adequadas, você pode permitir que seus filhos brinquem livremente, sabendo que eles estão protegidos.'
    }
  },
  {
    id: 6,
    title: {
      small: 'Proteção de ',
      big: 'áreas de lazer'
    },
    clientURLLink: '#',
    projectHeaderImage: `${Sacada02}`,
    videoLink: '',

    date: '6 August 2022',
    categories: [
      {
        id: 6,
        name: 'Sacadas',
        link: '#'
      }
    ],
    intro: {
      content:
        'Instalação de telas de proteção em sacadas para garantir a segurança de crianças e animais de estimação.',
      spmList: [
        {
          id: 6,
          name: 'Proteção de sacadas'
        }
      ]
    },
    description: {
      content:
        'A instalação de telas de proteção em portas é uma solução eficaz para evitar a entrada de insetos indesejados em sua casa. Além de manter sua casa livre de mosquitos, moscas e outros insetos, as telas também permitem uma boa circulação de ar e luz natural. Com as telas de proteção de portas, você pode desfrutar de um ambiente mais confortável e saudável em sua casa.'
    }
  },
  {
    id: 7,
    title: {
      small: 'Proteção de',
      big: 'telas em quadras esportivas'
    },
    clientURLLink: '#',
    projectHeaderImage: `${Futebol}`,
    videoLink: '',
    date: '6 de agosto de 2022',
    categories: [
      {
        id: 7,
        name: 'Quadras Esportivas',
        link: '#'
      }
    ],
    intro: {
      content:
        'Instalação de telas de proteção em quadras esportivas para garantir a segurança dos jogadores e evitar a perda de bolas durante as partidas.',
      spmList: [
        {
          id: 7,
          name: 'Proteção de quadras esportivas'
        },
        {
          id: 7,
          name: 'Redes de proteção para quadras esportivas'
        },
        {
          id: 7,
          name: 'Barreiras de segurança para esportes'
        },
        {
          id: 7,
          name: 'Telas de contenção em quadras'
        }
      ]
    },
    description: {
      content:
        'As telas de proteção em quadras esportivas oferecem segurança adicional aos jogadores e espectadores. Elas evitam que as bolas saiam da quadra, reduzindo o risco de acidentes e interrupções durante as partidas. Além disso, as telas também ajudam a manter a privacidade e a delimitar as áreas de jogo. Com a instalação das telas em quadras esportivas, você pode desfrutar de um ambiente esportivo seguro e controlado.'
    }
  },
  {
    id: 8,
    title: {
      small: 'Proteção de ',
      big: 'Coberturas'
    },
    clientURLLink: '#',
    projectHeaderImage: `${Cobertura}`,
    videoLink: '',
    date: '6 de agosto de 2022',
    categories: [
      {
        id: 8,
        name: 'Proteção de Coberturas',

        link: '#'
      }
    ],
    intro: {
      content:
        'Instalação de telas de proteção em coberturas para garantir a segurança de crianças e animais de estimação.',
      spmList: [
        {
          id: 8,
          name: 'Proteção de coberturas'
        },
        {
          id: 8,
          name: 'Redes de proteção para coberturas'
        },
        {
          id: 8,
          name: 'Barreiras de segurança para coberturas'
        },
        {
          id: 8,
          name: 'Telas de contenção em coberturas'
        }
      ]
    },
    description: {
      content:
        'As telas de proteção instaladas em coberturas são projetadas para garantir a segurança das crianças durante a diversão. Elas oferecem uma barreira de proteção em torno de playgrounds, áreas de recreação e quintais, evitando quedas e possíveis acidentes. Com as telas de proteção adequadas, você pode permitir que seus filhos brinquem livremente, sabendo que eles estão protegidos.'
    }
  },
  {
    id: 9,
    title: {
      small: 'Proteção de ',
      big: 'Janelas'
    },
    clientURLLink: '#',
    projectHeaderImage: `${Janelas02}`,
    videoLink: '',
    date: '6 de agosto de 2022',
    categories: [
      {
        id: 9,
        name: 'Proteção de Janelas',

        link: '#'
      }
    ],
    intro: {
      content:
        'Instalação de telas de janelas para garantir a segurança de crianças e animais de estimação.',
      spmList: [
        {
          id: 9,
          name: 'Proteção de janelas'
        },
        {
          id: 9,
          name: 'Redes de proteção para janelas'
        },
        {
          id: 9,
          name: 'Barreiras de segurança para janelas'
        },
        {
          id: 9,
          name: 'Telas de contenção em janelas'
        }
      ]
    },
    description: {
      content:
        'As telas de proteção instaladas em janelas são projetadas para garantir a segurança das crianças durante a diversão. Elas oferecem uma barreira de proteção em torno de playgrounds, áreas de recreação e quintais, evitando quedas e possíveis acidentes. Com as telas de proteção adequadas, você pode permitir que seus filhos brinquem livremente, sabendo que eles estão protegidos.'
    }
  },
  {
    id: 10,
    title: {
      small: 'Proteção de ',
      big: 'Sacadas'
    },
    clientURLLink: '#',
    projectHeaderImage: `${sacada04}`,
    videoLink: '',
    date: '01 de agosto de 2023',
    categories: [
      {
        id: 10,
        name: 'Proteção de Sacadas',

        link: '#'
      }
    ],
    intro: {
      content:
        'Instalação de telas em ambientes externos para garantir a segurança de crianças e animais de estimação.',
      spmList: [
        {
          id: 10,
          name: 'Proteção de sacadas'
        },
        {
          id: 10,
          name: 'Redes de proteção para sacadas'
        },
        {
          id: 10,
          name: 'Barreiras de segurança para sacadas'
        },
        {
          id: 10,
          name: 'Telas de contenção em sacadas'
        }
      ]
    },
    description: {
      content:
        'A sacada é um espaço muito apreciado em residências e apartamentos, proporcionando um ambiente agradável para relaxar, apreciar a paisagem e até mesmo receber visitas. No entanto, esse espaço pode se tornar um perigo, principalmente para crianças e animais de estimação, caso não seja adequadamente protegido. É nesse contexto que a instalação de telas de segurança em sacadas se torna uma medida essencial. A segurança das crianças é uma das principais razões pelas quais as telas são necessárias. As crianças são naturalmente curiosas e, muitas vezes, não têm a noção completa do perigo. Uma breve distração pode ser suficiente para que uma criança se aproxime da sacada e, sem a devida proteção, acontecer um acidente grave.'
    }
  }
]

export default projectData
