import { Link } from 'react-router-dom'

interface ProjectHeaderData {
  projectHeaderData: {
    projectHeaderImage: string
    title: {
      small: string
      big: string
    }
    date: string
    categories: {
      id: number
      name: string
      link: string
    }[]
  }
}

const ProjectDetailsHeader = ({ projectHeaderData }: ProjectHeaderData) => {
  return (
    <section
      className="page-header proj-det bg-img parallaxie valign"
      style={{
        backgroundImage: `url(${projectHeaderData.projectHeaderImage})`
      }}
      data-overlay-dark="4"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-9">
            <div className="cont">
              <h6>{projectHeaderData.title.small}</h6>
              <h2>{projectHeaderData.title.big}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="item mt-30">
              <h6>Categoria</h6>
              <p>
                {projectHeaderData.categories.map((cat, index) => (
                  <Link to={cat.link} key={cat.id}>
                    <a>
                      {cat.name}
                      {projectHeaderData.categories.length != index + 1
                        ? ' ,'
                        : ''}
                    </a>
                  </Link>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectDetailsHeader
