import styled from 'styled-components'

export const HeaderContainer = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
`

export const HeaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`

export const HeaderVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
`

export const HeaderContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
`

export const HeaderTitle = styled.h1`
  font-size: 40px;
  margin-bottom: 10px;
  color: #f16101;
`

export const HeaderSubtitle = styled.h2`
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.5;
  color: #fff !important;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`
