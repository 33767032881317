import LogoGarantia from './../../assets/images/garantia.webp'

interface Status {
  id: number
  number: string
  letter: string
  statusName: string
}

interface MyData {
  id: number
  smallTitle: string
  title: string
  content: string
  image: string
  status: Status[]
}

const About: MyData = {
  id: 1,
  smallTitle: 'MV Redes',
  title: 'Segurança e qualidade em redes de proteção',
  content:
    'Na busca por segurança e tranquilidade em nossas vidas, é essencial contar com soluções confiáveis. Quando se trata de telas de proteção, um item tão importante para a segurança de nossos entes queridos e animais de estimação, é essencial escolher uma opção que ofereça qualidade e durabilidade. É por isso que estamos orgulhosos de apresentar nossa garantia de 5 anos em todas as nossas telas de proteção.',
  image: `${LogoGarantia}`,
  status: [
    { id: 1, number: '3', letter: 'K', statusName: 'Happy Clients' },
    { id: 2, number: '14', letter: 'K', statusName: 'Success Projects' }
  ]
}

export default About
