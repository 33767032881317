import { useEffect, useState } from 'react'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/css/modal-video.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Quadra from '../../assets/images/services/quadra.webp'

const Futebol = () => {
  const [isOpen, setOpen] = useState(false)
  useEffect(() => {
    console.clear()
  }, [])
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  return (
    <section className="telas-esportivas block-sec">
      <div
        className="background bg-img pt-100 pb-0 parallaxie"
        style={{
          backgroundImage: `url(${Quadra})`
        }}
        data-overlay-dark="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="vid-area">
                <span className="text">Watch Video</span>
                <div className="vid-icon">
                  {typeof window !== 'undefined' && (
                    <ModalVideo
                      channel="youtube"
                      isOpen={isOpen}
                      videoId="V1NAlFO7oBU"
                      youtube={{
                        autoplay: 1
                      }}
                      onClose={() => setOpen(false)}
                    />
                  )}
                  <a
                    className="vid"
                    onClick={(e) => {
                      e.preventDefault()
                      setOpen(true)
                    }}
                  >
                    <div className="vid-butn">
                      <span className="icon">
                        <i className="fas fa-play"></i>
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="testim-box">
                <div className="head-box">
                  <h6 className="wow fadeIn" data-wow-delay=".5s">
                    Proteção Completa
                  </h6>
                  <h4 className="wow fadeInLeft" data-wow-delay=".5s">
                    Para suas Quadras Esportivas! 🏀
                  </h4>
                </div>
                <Slider
                  {...settings}
                  className="slic-item wow fadeInUp"
                  data-wow-delay=".5s"
                >
                  <div className="item">
                    <p>
                      🏆 A MV Redes tem a solução perfeita para manter a
                      segurança em suas quadras esportivas! Oferecemos a venda e
                      instalação de telas de proteção de alta qualidade,
                      garantindo que você possa aproveitar os jogos sem
                      preocupações. 🙌
                    </p>
                    <div className="info">
                      <div className="author">
                        <h6 className="author-name ">
                          {' '}
                          Confie na MV Redes para proteger suas quadras
                          esportivas e proporcionar tranquilidade para jogadores
                          e espectadores. 🤝💪 Entre em contato hoje mesmo e
                          deixe-nos cuidar da segurança de sua quadra esportiva!
                        </h6>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Futebol
