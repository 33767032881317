import Image01 from '../../assets/images/catArea/tela-protecao-animais.webp'
import Image02 from '../../assets/images/catArea/tela-protecao-cachorros.webp'
import Image03 from '../../assets/images/catArea/tela-protecao-gatos.webp'

interface Image {
  image1: string
  image2: string
  image3: string
}

interface Title {
  first: string
  second: string
}

interface Name {
  first: string
  second: string
}

interface Feature {
  id: number
  title: string
  name: Name
  wowDelay: string
}

interface MyData {
  id: number
  image: Image
  smallTitle: string
  title: Title
  content: string
  features: Feature[]
}

const catArea: MyData = {
  id: 1,
  image: {
    image1: `${Image01}`,
    image2: `${Image02}`,
    image3: `${Image03}`
  },
  smallTitle: 'Proteja seus animais de estimação',
  title: {
    first: 'Proteção em Primeiro Lugar',
    second:
      'Garanta a Segurança de seus Animais de Estimação com Telas de Proteção'
  },
  content:
    'Telas de proteção são essenciais para garantir a segurança de animais de estimação em casa. Elas evitam que os pets escapem, caiam de janelas ou varandas e também funcionam como barreira contra insetos. Essas telas são feitas com materiais resistentes e proporcionam tranquilidade aos tutores, prevenindo acidentes graves e garantindo um ambiente seguro para os animais de estimação.',
  features: [
    {
      id: 1,
      title: 'Prevenção',
      name: { first: 'Quedas', second: 'Barreiras preventivas' },
      wowDelay: '.3s'
    },
    {
      id: 2,
      title: 'Proteção',
      name: { first: 'Insetos', second: 'Saúde' },
      wowDelay: '.5s'
    },
    {
      id: 3,
      title: 'Segurança',
      name: { first: 'Economia', second: 'Conforto' },
      wowDelay: '.8s'
    }
  ]
}

export default catArea
