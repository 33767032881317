import React, { Component, ReactNode, RefObject } from 'react'
import Splitting from 'splitting'

interface SplitProps {
  children: ReactNode
}

class Split extends Component<SplitProps> {
  private target: RefObject<HTMLDivElement> = React.createRef()

  split = () => {
    if (this.target.current) {
      Splitting({ target: this.target.current })
    }
  }

  componentDidMount() {
    this.split()
  }

  componentDidUpdate() {
    this.split()
  }

  render() {
    return <div ref={this.target}>{this.props.children}</div>
  }
}

export default Split
