import cookie from 'cookie'
import { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Modal from './components/Modal'
import MainRoutes from './router'

const App = () => {
  const cookieAttributes = {
    sameSite: 'none' as const,
    secure: true
  }

  const cookieValue = '20'
  const serializedCookie = cookie.serialize(
    'mvredes',
    cookieValue,
    cookieAttributes
  )

  localStorage.setItem('mvredes', serializedCookie)

  const [isModalOpen, setIsModalOpen] = useState(true)

  useEffect(() => {
    setIsModalOpen(true)
  }, [])

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Router>
      <MainRoutes />
    </Router>
  )
}
export default App
