import Slider01 from '../../assets/images/slid/slider01.webp'
import Slider02 from '../../assets/images/slid/slider02.webp'
import Slider03 from '../../assets/images/slid/slider03.webp'

interface Slider {
  id: number
  image: string
  title: string
  date: string
}

const sliders: Slider[] = [
  {
    id: 1,
    image: `${Slider01}`,
    title:
      'Uma barreira essencial para prevenir acidentes e promover tranquilidade aos pais',
    date: 'Garantindo a Segurança das Crianças'
  },
  {
    id: 2,
    image: `${Slider02}`,
    title:
      'Uma medida fundamental para garantir a segurança e o bem-estar dos nossos companheiros peludos',
    date: 'Salvaguardando a Vida dos Animais de Estimação'
  },
  {
    id: 3,
    image: `${Slider03}`,
    title:
      'A importância das telas de proteção para evitar acidentes e proporcionar tranquilidade em áreas de piscinas residenciais.',
    date: 'Garantindo a Segurança em Torno das Piscinas'
  }
]

export default sliders
