import React, { useEffect, useRef } from 'react'
import SwiperCore, { EffectFade, Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
import caseStudyData from '../../data/sections/case'
import removeSlashFromPagination from '../../utils/removeSlashpagination'
SwiperCore.use([Navigation, Pagination, EffectFade])

const Case = () => {
  const [load, setLoad] = React.useState(true)
  const swiperRef = useRef<SwiperCore | null>(null)

  useEffect(() => {
    setTimeout(() => {
      setLoad(false)
      removeSlashFromPagination()
    }, 1000)
  }, [])

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const paginationRef = React.useRef(null)
  return (
    <section className="case-study">
      <h2 style={{ display: 'none' }}>&nbsp;</h2>
      <div
        id="content-carousel-container-unq-1"
        className="swiper-container"
        data-swiper="container"
      >
        {!load ? (
          <Swiper
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            speed={1000}
            parallax={true}
            navigation={
              navigationPrevRef.current && navigationNextRef.current
                ? {
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current
                  }
                : false
            }
            pagination={{
              clickable: true,
              el: paginationRef.current ? paginationRef.current : null
            }}
            onBeforeInit={(swiper) => {
              if (
                swiper.params.navigation &&
                typeof swiper.params.navigation !== 'boolean'
              ) {
                swiper.params.navigation.prevEl = navigationPrevRef.current!
                swiper.params.navigation.nextEl = navigationNextRef.current!
              }
              if (
                swiper.params.pagination &&
                typeof swiper.params.pagination !== 'boolean'
              ) {
                swiper.params.pagination.el = paginationRef.current!
              }
            }}
            className="swiper-wrapper"
            slidesPerView={1}
          >
            {caseStudyData.map((item) => (
              <SwiperSlide
                key={item.id}
                className="swiper-slide bg-img"
                style={{ backgroundImage: `url(${item.image})` }}
                data-overlay-dark="7"
              >
                <div className="container d-flex align-items-end">
                  <div className="cont">
                    <a>
                      <span>MV Redes</span>
                      <h6 className="main-color">{item.date}</h6>
                      <h4>{item.title}</h4>
                    </a>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}
        <div className="controls">
          <div
            ref={navigationNextRef}
            className="swiper-button-next swiper-nav-ctrl next-ctrl cursor-pointer"
          >
            <i className="fas fa-chevron-right"></i>
          </div>
          <div
            ref={navigationPrevRef}
            className="swiper-button-prev swiper-nav-ctrl prev-ctrl cursor-pointer"
          >
            <i className="fas fa-chevron-left"></i>
          </div>
        </div>
        <div className="swiper-pagination" ref={paginationRef}></div>
      </div>
      <div className="line bottom left"></div>
    </section>
  )
}

export default Case
