import React, { RefObject } from 'react'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import depoimentos from './data'

interface Testimonial {
  name: string
  mensagem: string
}

class Testimonials extends React.Component {
  private slider: RefObject<Slider> = React.createRef<Slider>()

  renderArrows = () => {
    return (
      <div className="arrows">
        <div className="container">
          <div
            onClick={() => this.slider.current?.slickNext()}
            className="next cursor-pointer"
          >
            <span className="pe-7s-angle-right"></span>
          </div>
          <div
            onClick={() => this.slider.current?.slickPrev()}
            className="prev cursor-pointer"
          >
            <span className="pe-7s-angle-left"></span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const sliderSettings: Settings = {
      dots: false,
      infinite: true,
      arrows: true,
      centerMode: true,
      autoplay: true,
      rows: 1,
      slidesToScroll: 1,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            centerMode: false
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerMode: false
          }
        }
      ]
    }

    return (
      <section
        id="testimonials-arch"
        className="testimonials section-padding position-re"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="sec-head  text-center">
                <h2 className="wow fadeIn" data-wow-delay=".3s">
                  Clientes Satisfeitos
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid position-re">
          <div className="row wow fadeInUp" data-wow-delay=".5s">
            <div className="col-lg-12">
              <Slider
                className="slic-item"
                ref={this.slider}
                {...sliderSettings}
              >
                {depoimentos.map((item: Testimonial, index: number) => (
                  <div className="item" key={index}>
                    <div className="info inf-lrg valign">
                      <div className="cont">
                        <div className="author">
                          <div className="img">
                            <img
                              src="https://bvweb.com.br/assets/empresas/mvredes/images/testimonials/1.jpg"
                              alt=""
                            />
                          </div>
                          <h6 className="author-name color-font">
                            {item.name}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="valign">
                      <p className="fz-20 fw-200">{item.mensagem}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          {this.renderArrows()}
        </div>
        <div className="line bottom left"></div>
      </section>
    )
  }
}

export default Testimonials
