const LatestNews = () => {
  return (
    <section className="blog section-padding" id="blog-arch">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
              <h2 className="wow fadeIn" data-wow-delay=".3s">
                Últimos serviços
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="item md-mb50 wow fadeInUp" data-wow-delay=".3s">
              <div className="img">
                <img src="/img/arch/services/01.png" alt="" />
              </div>
              <div className="cont">
                <div>
                  <div className="info">
                    <p className="date">
                      <span>
                        <h1>Proteção</h1> de área
                      </span>
                    </p>
                  </div>
                  <h5>
                    <p>
                      A proteção da área de areia com telas de proteção é uma
                      medida essencial para garantir a segurança das crianças e
                      evitar acidentes indesejados. As telas de proteção atuam
                      como uma barreira eficaz, impedindo que as crianças se
                      afastem da área delimitada e evitando o acesso de animais
                      indesejados.
                    </p>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="item md-mb50 wow fadeInUp" data-wow-delay=".5s">
              <div className="img">
                <img src="/img/arch/services/02.png" alt="" />
              </div>
              <div className="cont">
                <div>
                  <div className="info">
                    <p className="date">
                      <span>
                        <h1>Proteção</h1> de janelas
                      </span>
                    </p>
                  </div>
                  <h5>
                    <p>
                      A proteção de janelas com telas de proteção é uma medida
                      importante para garantir a segurança de crianças e animais
                      de estimação dentro de casa. As telas de proteção atuam
                      como uma barreira eficaz, impedindo quedas acidentais e
                      evitando que os pequenos ou os animais se aproximem demais
                      das janelas.
                    </p>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line bottom left"></div>
    </section>
  )
}

export default LatestNews
