import Cobertura from '../../assets/images/servicesDetails/cobertura.webp'
import Escadas from '../../assets/images/servicesDetails/escadas.webp'
import Janela01 from '../../assets/images/servicesDetails/janela01.webp'
import Janela02 from '../../assets/images/servicesDetails/janela02.webp'
import Piscina01 from '../../assets/images/servicesDetails/piscina01.webp'
import Futebol from '../../assets/images/servicesDetails/quadracondominio.webp'
import Sacada01 from '../../assets/images/servicesDetails/sacada01.webp'
import Sacada02 from '../../assets/images/servicesDetails/sacada02.webp'
import Sacada04 from '../../assets/images/servicesDetails/sacada04.webp'
import Sacada05 from '../../assets/images/servicesDetails/sacada05.webp'

interface Service {
  id: number
  nome: string
  categorias: string
  imagem: string
  description: string
}

const services: Service[] = [
  {
    id: 1,
    nome: 'Proteção de Janela',
    categorias: 'janelas',
    imagem: `${Janela01}`,
    description: 'Instalação de tela de proteção em janela residencial.'
  },
  {
    id: 2,
    nome: 'Proteção de Escadas',
    categorias: 'escadas',
    imagem: `${Escadas}`,
    description:
      'Proteção de escadas com tela de segurança para garantir a segurança de crianças e animais de estimação.'
  },
  {
    id: 3,
    nome: 'Proteção Externa',
    categorias: 'externos',
    imagem: `${Sacada01}`,
    description:
      'Telas de proteção instaladas em áreas externas para evitar a entrada de animais indesejados.'
  },
  {
    id: 4,
    nome: 'Proteção de Piscinas',
    categorias: 'piscinas',
    imagem: `${Piscina01}`,
    description:
      'Instalação de telas de proteção ao redor de piscinas para prevenir acidentes com crianças e animais.'
  },
  {
    id: 5,
    nome: 'Proteção Externa',
    categorias: 'externos',
    imagem: `${Sacada02}`,
    description:
      'Telas de proteção instaladas em áreas externas para evitar a entrada de animais indesejados.'
  },

  {
    id: 6,
    nome: 'Proteção de Áreas de Lazer',
    categorias: 'externos',
    imagem: `${Sacada05}`,
    description:
      'Telas de proteção instaladas em áreas de lazer para garantir a segurança das crianças durante a diversão.'
  },
  {
    id: 7,
    nome: 'Segurança em Quadras Esportivas',
    categorias: 'quadras',
    imagem: `${Futebol}`,
    description:
      'Telas de proteção instaladas em áreas de lazer para garantir a segurança das crianças durante a diversão.'
  },
  {
    id: 8,
    nome: 'Proteção de Coberturas',
    categorias: 'externos',
    imagem: `${Cobertura}`,
    description:
      'Telas de proteção instaladas em áreas de lazer para garantir a segurança das crianças durante a diversão.'
  },
  {
    id: 9,
    nome: 'Proteção de Janela',
    categorias: 'janelas',
    imagem: `${Janela02}`,
    description: 'Instalação de tela de proteção em janela residencial.'
  },
  {
    id: 10,
    nome: 'Proteção de Sacadas',
    categorias: 'externos',
    imagem: `${Sacada04}`,
    description: 'Instalação de tela de proteção em sacada residencial.'
  }
]

export default services
