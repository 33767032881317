import Escada from '../../assets/images/services/escada.webp'
import Janela from '../../assets/images/services/menino-janela.webp'
import PiscinaProtecao from '../../assets/images/services/piscina-protecao.webp'
import Quadra from '../../assets/images/services/quadra.webp'
import SacadaTelas from '../../assets/images/services/sacada-telas.webp'
import TerracosTelas from '../../assets/images/services/terracos-telas.webp'

const Services = () => {
  return (
    <section className="serv-arch">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div
            className="col-lg col-md-6 item bg-img"
            style={{
              backgroundImage: `url(${Escada})`
            }}
          >
            <h6 className="numb">Escadas</h6>
            <h5>Telas de proteção em escadas</h5>
            <p>
              Segurança vertical assegurada. Evite quedas e acidentes,
              proporcionando um ambiente seguro para sua família e crianças. Com
              telas de proteção de alta resistência, desfrute da tranquilidade
              de uma escada protegida em todos os momentos.
            </p>
          </div>

          <div
            className="col-lg col-md-6 item bg-img"
            style={{
              backgroundImage: `url(${Janela})`
            }}
          >
            <h6 className="numb">Janelas</h6>
            <h5>Telas de proteção em janelas</h5>
            <p>
              Segurança em primeiro lugar. Proteja sua família, crianças e
              animais de estimação, evitando riscos de quedas e acidentes.
              Desfrute da tranquilidade em sua casa, sabendo que suas janelas
              estão protegidas com telas de qualidade e durabilidade.
            </p>
          </div>

          <div
            className="col-lg col-md-6 item bg-img"
            style={{
              backgroundImage: `url(${PiscinaProtecao})`
            }}
          >
            <h6 className="numb">Piscinas</h6>
            <h5>Telas de proteção em piscinas</h5>
            <p>
              Segurança aquática garantida. Previna acidentes e mantenha sua
              família protegida com telas resistentes, impedindo o acesso não
              autorizado à piscina. Desfrute de momentos de lazer com
              tranquilidade, sabendo que sua piscina está segura e protegida.
            </p>
          </div>

          <div
            className="col-lg col-md-6 item bg-img"
            style={{
              backgroundImage: `url(${SacadaTelas})`
            }}
          >
            <h6 className="numb">Sacadas</h6>
            <h5>Telas de proteção em sacadas</h5>
            <p>
              Segurança em alturas elevadas. Proteja sua família, crianças e
              animais de estimação de quedas acidentais, proporcionando um
              ambiente seguro para desfrutar de sua sacada. Com telas de
              proteção confiáveis e duráveis, tenha tranquilidade em momentos de
              lazer ao ar livre.
            </p>
          </div>

          <div
            className="col-lg col-md-6 item bg-img"
            style={{
              backgroundImage: `url(${TerracosTelas})`
            }}
          >
            <h6 className="numb">Terraços</h6>
            <h5>Telas de proteção em terraços</h5>
            <p>
              Segurança elevada garantida. Mantenha seu espaço externo protegido
              e evite riscos de quedas acidentais para sua família e animais de
              estimação. Desfrute de momentos relaxantes em seu terraço, com a
              tranquilidade de telas de proteção de alta qualidade.
            </p>
          </div>
          <div
            className="col-lg col-md-6 item bg-img"
            style={{
              backgroundImage: `url(${Quadra})`
            }}
          >
            <h6 className="numb">Quadras esportivas</h6>
            <h5>Proteção eficiente para jogadores e espectadores.</h5>
            <p>
              As redes de proteção garantem a segurança em quadras esportivas,
              prevenindo acidentes e garantindo a diversão. Feitas de materiais
              duráveis e resistentes, essas redes contêm bolas e objetos
              lançados com força, evitando danos e lesões. Instaladas por
              profissionais especializados, proporcionam um ambiente seguro
              tanto para jogadores quanto para espectadores.
            </p>
          </div>
        </div>
      </div>
      <div className="line bottom left"></div>
    </section>
  )
}

export default Services
