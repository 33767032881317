interface ProjectDescriptionData {
  projectDescriptionData: {
    content: string
  }
}

const ProjectDescription = ({
  projectDescriptionData
}: ProjectDescriptionData) => {
  return (
    <section className="intro-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <div className="htit">
              <h4>
                <span>02 </span> Descrição
              </h4>
            </div>
          </div>
          <div className="col-lg-8 offset-lg-1 col-md-8">
            <div className="text js-scroll__content">
              <p className="extra-text">{projectDescriptionData.content}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="line bottom left"></div>
    </section>
  )
}

export default ProjectDescription
