import { useEffect, useRef } from 'react'
import About from '../../components/About'
import Case from '../../components/Case'
import CatsArea from '../../components/CatsArea'
import Contact from '../../components/Contact'
import Footer from '../../components/Footer'
import Futebol from '../../components/Futebol'
import HeaderVideo from '../../components/HeaderVideo'
import LatestNews from '../../components/Latest-news'
import Navbar from '../../components/Navbar'
import Security from '../../components/Security'
import Services from '../../components/Services'
import Testimonials from '../../components/Testimonials'
import Works from '../../components/Works'

export const Home = () => {
  const navbarRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      const navbar = navbarRef.current
      if (navbar && window.pageYOffset > 300) {
        navbar.classList.add('nav-scroll')
      } else {
        navbar?.classList.remove('nav-scroll')
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <Navbar navbarRef={navbarRef} />
      <HeaderVideo />
      <About />
      <CatsArea />
      <Services />
      <Security />
      <Works />
      <Case />
      <Testimonials />
      <Futebol />
      <LatestNews />
      <Contact />
      <Footer />
    </>
  )
}
