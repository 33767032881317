const depoimentos = [
  {
    name: 'Ana Paula',
    mensagem:
      'Fiquei extremamente satisfeita com o serviço de instalação de telas de proteção em minha casa. Agora posso deixar as janelas abertas sem me preocupar com a segurança dos meus filhos. Recomendo o serviço!'
  },
  {
    name: 'João Pedro',
    mensagem:
      'As telas de proteção instaladas em minha varanda foram um investimento que trouxe paz de espírito para toda a família. Meus gatos podem aproveitar o espaço sem risco de fugas ou acidentes. Excelente trabalho!'
  },
  {
    name: 'Marina Silva',
    mensagem:
      'Contratei o serviço de instalação de telas de proteção para a área da piscina e estou muito satisfeita. Agora posso relaxar sabendo que meus filhos estão seguros ao brincarem ao redor da piscina. Profissionais altamente competentes!'
  },
  {
    name: 'Carlos Eduardo',
    mensagem:
      'Recomendo fortemente o serviço de telas de proteção! Além de me proporcionar um ambiente seguro para meus filhos e animais de estimação, as telas são discretas e não comprometem a estética da casa. Ótimo trabalho!'
  },
  {
    name: 'Gustavo Henrique',
    mensagem:
      'Estou encantada com o serviço de telas de proteção realizado em minha residência. Agora posso desfrutar de uma brisa fresca nas janelas sem me preocupar com a segurança dos meus filhos pequenos. Profissionais atenciosos e eficientes!'
  }
]

export default depoimentos
