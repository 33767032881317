import getSiblings from './getSiblings'

export const handleDropdown = (e: Event): void => {
  const target = e.target as HTMLElement
  const parentElement = target.parentElement
  if (!parentElement) {
    return
  }

  getSiblings(parentElement)
    .filter((item: any) => item.classList.contains('show'))
    .map((item: any) => {
      item.classList.remove('show')
      if (item.childNodes[0]) {
        ;(item.childNodes[0] as HTMLElement).setAttribute(
          'aria-expanded',
          'false'
        )
      }
      if (item.childNodes[1]) {
        ;(item.childNodes[1] as HTMLElement).classList.remove('show')
      }
    })
  parentElement.classList.toggle('show')
  target.setAttribute('aria-expanded', 'true')
  const childNode = parentElement.childNodes[1] as HTMLElement
  if (childNode) {
    childNode.classList.toggle('show')
  }
}

export const handleMobileDropdown = (): void => {
  const navbar = document.getElementById('navbarSupportedContent')
  if (navbar) {
    navbar.classList.toggle('show-with-trans')
  }
}
