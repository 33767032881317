import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import initIsotope from '../../utils/initIsotope'
import services from './servicos'

interface Service {
  id: number
  nome: string
  categorias: string
  imagem: string
  description: string
}

const Works = () => {
  useEffect(() => {
    setTimeout(() => {
      initIsotope()
    }, 1000)
  }, [])

  return (
    <section className="portfolio po-arch section-padding pb-70" id="po-arch">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
              <h2 className="wow fadeIn" data-wow-delay=".3s">
                Serviços
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="filtering col-12">
            <div className="filter custom-font wow fadeIn" data-wow-delay=".5s">
              <span data-filter="*" className="active">
                Todos
              </span>
              <span data-filter=".janelas">Janelas</span>
              <span data-filter=".escadas">Escadas</span>
              <span data-filter=".externos">Externos</span>
              <span data-filter=".quadras">Quadras esportivas</span>
            </div>
          </div>

          <div className="gallery full-width">
            {services.map((projeto: Service) => (
              <div
                key={projeto.id}
                className={`col-md-6 items ${projeto.categorias} wow fadeInUp`}
                data-wow-delay=".4s"
              >
                <div className="item-img">
                  <a className=" wow">
                    <img src={projeto.imagem} alt="mv redes" />
                    <div className="item-img-overlay"></div>
                  </a>
                </div>
                <div className="cont">
                  <h5>{projeto.nome}</h5>
                  <span className="author-details">{projeto.description}</span>
                </div>
                <div className="cont border-bottom">
                  <Link to={`/details/${projeto.id}`}>
                    <h5>Ver mais</h5>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="line bottom left"></div>
    </section>
  )
}

export default Works
