interface ProjectIntroductionData {
  projectIntroductionData: {
    content: string
    spmList: {
      id: number
      name: string
    }[]
  }
}

const ProjectIntroduction = ({
  projectIntroductionData
}: ProjectIntroductionData) => {
  return (
    <section className="intro-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <div className="htit">
              <h4>
                <span>01 </span> Sobre
              </h4>
            </div>
          </div>
          <div className="col-lg-8 offset-lg-1 col-md-8">
            <div className="text js-scroll__content">
              <p className="extra-text">{projectIntroductionData.content}</p>
              <ul className="smp-list mt-30">
                {projectIntroductionData.spmList.map((item) => (
                  <li key={item.id}>{item.name}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="line bottom left"></div>
    </section>
  )
}

export default ProjectIntroduction
