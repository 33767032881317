import React, { useEffect, useState } from 'react'
import { Link } from 'react-scroll'
import LogoDark from '../../assets/images/logo-dark.webp'
import LogoLight from '../../assets/images/logo-light.webp'
import { handleMobileDropdown } from '../../utils/navbar'

interface NavbarArchProps {
  navbarRef: React.RefObject<HTMLDivElement>
}

const NavbarArch = ({ navbarRef }: NavbarArchProps) => {
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    }

    scrollToTop()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  return (
    <nav className="navbar navbar-expand-lg" ref={navbarRef}>
      <div className="container">
        <a className="logo" href="/">
          {scrollPosition === 0 ? (
            <img src={LogoLight} alt="logo" />
          ) : (
            <img src={LogoDark} alt="logo" />
          )}
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          onClick={handleMobileDropdown}
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  onClick={scrollToTop}
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  to="home"
                >
                  Home
                </Link>
              </li>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link"
                to="about"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                Sobre Nós
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="cat"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                Proteção de Animais
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="po-arch"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                Serviços
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="testimonials-arch"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                Depoimentos
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="blog-arch"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                Últimos Projetos
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="contact-arch"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                Contato
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavbarArch
