import appData from '../../data/app.json'

interface IFooter {
  hideBGCOLOR?: boolean
}

const Footer = ({ hideBGCOLOR }: IFooter) => {
  return (
    <footer className={`${!hideBGCOLOR ? 'sub-bg' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="item md-mb50">
              <div className="title">
                <h5>Contato</h5>
              </div>
              <ul>
                <li>
                  <span className="icon pe-7s-map-marker"></span>
                  <div className="cont">
                    <h6> Vila Nova - Porto Alegre - RS</h6>
                    <p>Frederico Carlos Gomes - 195</p>
                  </div>
                </li>
                <li>
                  <span className="icon pe-7s-mail"></span>
                  <div className="cont">
                    <h6>Email</h6>
                    <p>contato@mvredes.com.br</p>
                  </div>
                </li>
                <li>
                  <span className="icon pe-7s-call"></span>
                  <div className="cont">
                    <h6>Whatsapp</h6>
                    <p>(51) 9985-3781</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item md-mb50">
              <div className="title">
                <h5>Últimos serviços</h5>
              </div>
              <ul>
                <li>
                  <div className="img garantia">
                    <a>
                      <img src="/img/garantia.png" alt="Garantia" />
                    </a>
                  </div>
                  <div className="sm-post">
                    <p>Garantimos a qualidade de nossos serviços.</p>
                    <span className="date">5 anos de garantia</span>
                  </div>
                </li>
                <li>
                  <div className="sm-post">
                    <a>
                      <p>
                        Proteja sua família e seu lar com telas de proteção.
                        Desfrute de tranquilidade e bem-estar
                      </p>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item">
              <div className="logo footer">
                <img src={appData.lightLogo} alt="MV Redes" />
              </div>
              <div className="social">
                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=100083982491735"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=555199853781"
                >
                  <i className="fab fa-whatsapp"></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/mvredesprotecao"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
              <div className="copy-right">
                <a
                  target="_blank"
                  className="logo-bvweb-link"
                  href="https://bvweb.com.br"
                >
                  <img
                    className="logo-bvweb"
                    src="https://bvweb.com.br/assets/images/logo_bvweb.png"
                    alt="BVWEB Designer"
                  />
                  <p>BVWEB Designer</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
