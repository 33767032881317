import React from 'react'

interface DivProps {
  id?: string
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
  onMouseEnter?: () => void
}

export default function Div(props: DivProps) {
  return <div {...props}>{props.children}</div>
}
