import { useEffect } from 'react'
import * as S from './styles'

const HeaderVideo = () => {
  useEffect(() => {
    const parallaxScroll = () => {
      const header = document.querySelector('.header') as HTMLElement

      if (header) {
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop
        header.style.backgroundPositionY = `-${scrollTop}px`
      }
    }

    window.addEventListener('scroll', parallaxScroll)

    return () => {
      window.removeEventListener('scroll', parallaxScroll)
    }
  }, [])

  return (
    <S.HeaderContainer className="header">
      <S.HeaderOverlay className="header-overlay" />
      <S.HeaderVideo autoPlay muted loop className="header-video">
        <source
          src="https://bvweb.com.br/assets/videos/mvredes.mp4"
          type="video/mp4"
        />
      </S.HeaderVideo>
      <S.HeaderContent className="header-content">
        <S.HeaderTitle className="fw-600 text-u ls1 mb-30 color-font">
          Telas de Proteção
        </S.HeaderTitle>
        <S.HeaderSubtitle>
          Projetadas para prevenir acidentes, essas telas são instaladas ao
          redor da piscina, formando uma barreira física que impede o acesso não
          autorizado, especialmente de crianças pequenas e animais de estimação.
        </S.HeaderSubtitle>
      </S.HeaderContent>
    </S.HeaderContainer>
  )
}

export default HeaderVideo
