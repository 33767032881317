const About = () => {
  return (
    <section id="about" className="about-ar section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 valign">
            <div className="img">
              <div
                className="bg-img bg-fixed hero-bg"
                style={{
                  backgroundImage:
                    'url(http://bvweb.com.br/assets/empresas/mvredes/images/about/hero.jpg)'
                }}
              ></div>
              <div className="exp valign text-center">
                <div className="full-width">
                  <h2
                    className="bg-img bg-fixed"
                    style={{
                      backgroundImage:
                        'url(http://bvweb.com.br/assets/empresas/mvredes/images/about/hero.jpg)'
                    }}
                  >
                    12
                  </h2>
                  <p>12 anos de experiência</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 valign">
            <div className="content">
              <h6 className="sub-title main-color ls10 text-u">
                MV Redes: Proteção Confiança
              </h6>
              <h3>Especialistas em Telas de Proteçã</h3>
              <p>
                Bem-vindo à MV Redes, uma empresa dedicada a fornecer soluções
                de proteção confiáveis para janelas, sacadas e qualquer local
                que necessite de telas de proteção. Com anos de experiência no
                ramo, nos orgulhamos de oferecer produtos de alta qualidade e
                serviços especializados para garantir a segurança e
                tranquilidade de nossos clientes. Nossas telas de proteção são
                fabricadas com materiais resistentes e duráveis, projetadas para
                suportar as mais diversas condições climáticas. Trabalhamos com
                uma equipe de profissionais qualificados, que realiza a
                instalação das telas de forma precisa e eficiente, garantindo a
                máxima segurança em cada projeto. Na MV Redes, entendemos a
                importância de proteger sua família e animais de estimação
                contra riscos desnecessários. Nossas telas de proteção são
                projetadas não apenas para cumprir os mais altos padrões de
                segurança, mas também para se integrarem harmoniosamente ao
                design do seu ambiente, sem comprometer a estética. Navegue em
                nosso site para conhecer mais sobre nossos produtos e serviços.
                Estamos prontos para oferecer a solução de proteção ideal para
                suas necessidades específicas. Conte com a MV Redes para uma
                proteção confiável e uma experiência de excelência em telas de
                proteção.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="line bottom left"></div>
    </section>
  )
}

export default About
