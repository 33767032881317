import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik'
import { useRef } from 'react'
import Div from '../Div'

interface ContactFormValues {
  name: string
  email: string
  message: string
}

const Contact = () => {
  const messageRef = useRef<HTMLDivElement>(null)

  function validateEmail(value: string) {
    let error: string | undefined
    if (!value) {
      error = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Invalid email address'
    }
    return error
  }

  const sendMessage = (ms: number) =>
    new Promise<void>((r) => setTimeout(r, ms))

  return (
    <section
      id="contact-arch"
      className="contact-sec style2 section-padding position-re bg-img"
      style={{ backgroundImage: 'url(/img/patrn1.png)' }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
              <h2 className="wow fadeIn" data-wow-delay=".3s">
                Contato
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="form wow fadeInUp" data-wow-delay=".5s">
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  message: ''
                }}
                validate={(values) => {
                  const errors: FormikErrors<ContactFormValues> = {}
                  if (!values.name) {
                    errors.name = 'Required'
                  }
                  if (!values.email) {
                    errors.email = 'Required'
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = 'Invalid email address'
                  }
                  if (!values.message) {
                    errors.message = 'Required'
                  }
                  return errors
                }}
                onSubmit={async (values, { resetForm }) => {
                  await sendMessage(500)
                  alert(JSON.stringify(values, null, 2))
                  // show message
                  if (messageRef.current) {
                    messageRef.current.innerText =
                      'Mensagem enviada com sucesso!'
                  }
                  // Reset the values
                  resetForm()
                  // clear message
                  setTimeout(() => {
                    if (messageRef.current) {
                      messageRef.current.innerText = ''
                    }
                  }, 2000)
                }}
              >
                <Form id="contact-form">
                  <div className="messages" ref={messageRef}></div>
                  <br />

                  <div className="controls">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Field
                            id="form_name"
                            type="text"
                            name="name"
                            placeholder="Nome"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Field
                            validate={validateEmail}
                            id="form_email"
                            type="email"
                            name="email"
                            placeholder="Mensagem"
                            required
                          />
                          <ErrorMessage name="email" component="div" />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <Field
                            as="textarea"
                            id="form_message"
                            name="message"
                            placeholder="Message"
                            rows="4"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="text-center">
                          <button
                            type="submit"
                            className="nb butn light mt-30 full-width"
                          >
                            <span className="ls3 text-u">Enviar mensagem</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Div className="cs-google_map">
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3451.0657511038635!2d-51.20190728443011!3d-30.120931981854582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9519833da435354f%3A0x25b8e9ac21c22231!2sBeco%20Imp%C3%A9rio%20-%20Vila%20Nova%2C%20Porto%20Alegre%20-%20RS%2C%2091787-415!5e0!3m2!1spt-BR!2sbr!4v1659492047342!5m2!1spt-BR!2sbr"
          allowFullScreen
          title="Google Map"
        /> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3451.117686760089!2d-51.20705562360874!3d-30.11944573476008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xaaed3a18bc37daf9%3A0xa159b2516af17b0b!2sMv%20redes%20telas%20de%20prote%C3%A7%C3%A3o!5e0!3m2!1spt-BR!2sbr!4v1686445660020!5m2!1spt-BR!2sbr"
          width="600"
          height="450"
          allowFullScreen
          loading="lazy"
        ></iframe>
      </Div>
    </section>
  )
}

export default Contact
