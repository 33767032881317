import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import NextProject from '../../components/NextProject'

import ProjectDescription from '../../components/ProjectDescription'
import ProjectDetailsHeader from '../../components/ProjectDetailsHeader'
import ProjectIntroduction from '../../components/ProjectIntroduction'
import projectData from '../../data/serviceDetails'

const Details = () => {
  const navbarRef = useRef<HTMLDivElement | null>(null)
  const params = useParams()
  const projectId = params.id
  const projectDetails = projectData?.find(
    (project) => project?.id.toString() === projectId
  )
  console.log(projectDetails)

  if (!projectDetails) {
    return null
  }

  useEffect(() => {
    const handleScroll = () => {
      const navbar = navbarRef.current
      if (navbar && window.pageYOffset > 300) {
        navbar.classList.add('nav-scroll')
      } else {
        navbar?.classList.remove('nav-scroll')
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <>
      <Navbar navbarRef={navbarRef} />
      <div className="wrapper">
        <ProjectDetailsHeader projectHeaderData={projectDetails} />
        <ProjectIntroduction projectIntroductionData={projectDetails.intro} />
        <ProjectDescription
          projectDescriptionData={projectDetails.description}
        />
        <NextProject />
        <Footer />
      </div>
    </>
  )
}

export default Details
