import Hiper from '../../assets/images/hipercard.webp'
import MasterCard from '../../assets/images/mastercard.webp'
import Visa from '../../assets/images/visa.webp'
import AboutData from '../../data/sections/about'

const Security = () => {
  return (
    <section className="about-us section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 valign md-mb50">
            <div className="mb-50">
              <h6 className="fw-100 text-u ls10 mb-10">
                {AboutData.smallTitle}
              </h6>
              <h3 className="fw-600 text-u ls1 mb-30 color-font">
                {AboutData.title}
              </h3>
              <p>{AboutData.content}</p>
            </div>
          </div>
          <div className="col-lg-7 img">
            <img src={AboutData.image} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 valign">
            <div className="mb-50">
              <h3 className="fw-600 text-u ls1 mb-30 color-font">
                Facilitamos o pagamento
              </h3>
              <p>Parcelamos em até 12x sem juros no cartão</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div className="box images">
                  <img src={MasterCard} alt="Mastercard" />
                  <img src={Visa} alt="Visa" />
                  <img src={Hiper} alt="Hipercard" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line bottom right"></div>
    </section>
  )
}

export default Security
