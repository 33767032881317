const WhatsAppWidget = () => {
  return (
    <div>
      <a
        className="whatsapp-button"
        href="https://api.whatsapp.com/send?phone=555199853781"
        target="_blank"
      >
        <i className="fab fa-whatsapp"></i>
      </a>
    </div>
  )
}

export default WhatsAppWidget
