import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`

.blog-crv .item {
  display: flex;
  align-items: center;
  height: 100%;
}
.block-sec .testim-box .slick-dots li button {
  display: block;
  opacity: 0;
  width: 0;
  height: 0;
}
.slider .social-icon a {
  margin: 0 3px;
}
.navbar .navbar-nav .nav-link,
.video .vid .vid-butn .icon,
.video-wrapper .vid .vid-butn .icon {
  cursor: pointer;
}
.block-sec .vid-area {
  z-index: 99;
}
.div-tooltip-tit,
.div-tooltip-sub {
  display: block;
  padding: 0;
}
section.testimonials {
  overflow: hidden;
}
.contact-page .pages-header {
  overflow: hidden;
}
div#ieatmaps iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
button.nb {
  background: transparent;
  color: #fff;
  cursor: pointer;
}

button.nb:hover {
  background: #fff;
  color: #222;
}
.replay {
  cursor: pointer;
}

.pace-done #preloader:after {
  bottom: 0;
}
#preloader {
  top: 0;
  left: 0;
}

.pace-done #preloader {
  visibility: hidden;
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
}
.pace-done #preloader:after,
.pace-done #preloader:before {
  height: 0;
  -webkit-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
body.hideX #preloader {
  display: none !important;
  z-index: -1111111;
  height: 0;
  width: 0;
  overflow: hidden;
}
body.hideX .pace {
  display: none !important;
  background-color: transparent;
}
.app-footer .social a {
  margin-right: 4px;
}

.col-lg-6.valign p {
  font-size: 12px !important;
}

.line {
  position: relative;
  height: 2px;
  width: 65%;
  background: #f16101 !important;
  z-index: 9999999999;
  margin-top: 2rem;
}

.line.top {
  top: 0;
}

.line.bottom {
  bottom: 0;
}

.line.left {
  left: 0;
}

.line.right {
  right: 0;
}

.box.images {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.box.images > img {
  width: 100px;
}

@media screen and (max-width: 767px) {
  .box.images > img {
    width: 60px;
  }
}

.cont.border-bottom h5 {
  color: #f16101 !important;
  cursor: pointer !important;
}

a.logo-bvweb-link {
  width: 80%;

  .logo-bvweb {
    width: 12%;
  }
}

.whatsapp-button {
  right: 30px;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 110px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  justify-content: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 10000;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}

.cs-google_map {
  height: 600px;
  margin-top: 6rem;
  iframe {
    height: 100%;
    display: block;
    border: none;
    width: 100%;
    /* filter: grayscale(100%) invert(90%) contrast(120%); */
    border: 1px solid #f16101;
  }
  &.cs-type1 {
    height: 100%;
    min-height: 300px;
    width: 50vw;
  }
}

@media screen and (max-width: 991px) {

  .cs-google_map {
    height: 400px;
  }

  .cs-google_map.cs-type1 {
    width: 100%;
    height: 400px;
    border-radius: 15px;
    overflow: hidden;
  }

}



`

export default GlobalStyles
